@charset "utf-8";
@import '~material-icons/iconfont/material-icons.css';
@import './components/style.scss';
@import './components/adaptive.scss';
@import './components/colors.scss';
@import './components/icon.scss';
@import './components/forms.scss';
@import "~bootstrap-4-grid/scss/grid.scss";
@import "~font-awesome/css/font-awesome.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./components/styles.scss";
@import "~ngx-toastr/toastr";
