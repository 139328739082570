$screen-xs-max: 320px;

$screen-sm-max: 575px;

// Small tablets (portrait view)
$screen-md-max: 767px;

$screen-mob-max: 991px;

// Tablets and small desktops
$screen-lg-min: 992px;

@mixin xs {
  @media (max-width: #{$screen-xs-max}) { // <= 320px
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$screen-sm-max}) { // <= 575px
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-max}) { // <= 767px
    @content;
  }
}
@mixin mob {
  @media (max-width: #{$screen-mob-max}) { // <= 991px
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) { // 992px <=
    @content;
  }
}

@mixin rwd($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}
