body {
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    background-color: #fff;
}
span {
    &::after,
    &::before {
        box-sizing: border-box;
    }
}
a {
    text-decoration: none;
    background-color: transparent;
}
input {
    outline: none;
}
label {
    display: inline-block;
    margin-bottom: .5rem;
    &::after,
    &::before {
        box-sizing: border-box;
    }
}
.form-group {
    margin-bottom: 1rem;
}
.form-text {
    display: block;
    margin-top: .25rem;
}
.small, small {
    font-size: 80%;
    font-weight: 400;
}
.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control:disabled {
    cursor: not-allowed;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    &::after,
    &::before {
        box-sizing: border-box;
    }
}
.h3, h3 {
    font-size: 1.75rem;
}
.h4, h4 {
    font-size: 1.5rem;
}
.h5, h5 {
    font-size: 1.1rem;
}
table {
    border-collapse: collapse;
    &::after,
    &::before {
    box-sizing: border-box;
    }
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}
.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
}
th {
    text-align: inherit;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}
.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.table-bordered, .table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
}
.table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 2px;
}
