$bg-color--main--lighten: #FBFBFB;
$bg-color--main--light: #F6F7F9;
$bg-color--main: #D2D5DC;
$bg-color--secondary--light: #272E39;
$bg-color--secondary: #151823;
$bg-color--secondary--dark: #0f1118;
$bg-color: #E8E9EB;

$bg-color--1: #707585;
$bg-color--2: #3E4352;
$bg-color--3: #40475E;
$bg-color--4: #292E3F;

$white: #FFFFFF;

$color-secondary:#F8F0EF;
$color-secondary--dark: #F8D6D2;
$color-main--light: #CB3415;
$color-main: #a12f18;
$color-third: #27AE60;
$color-third--dark: #219653;

$text-color--secondary--light: #B9BABD;
$text-color--secondary: #A1A3A7;
$text-color--secondary--dark: #8A8B91;
$text-color--main--light: #455473;
$text-color--main: #3B3E48;
$text-color--main--dark: rgba(59, 62, 72, 0.7);

$border-color: #DAE1EF;

$green--lighten: #E3FCEF;
$green--light: #DCF5E3;
$green: #27AE60;

$yellow--light:#F5F4DC;
$yellow: #FEC42D;

$red--lighten: #F6EBE8;
$red--light: #F9DDDD;
$red: #F04747;
$red--dark: #CC3737;
$red--darken: #a12f17;

$gray--light: #EDEDED;
$gray--lightest: #F8F8FB;
$gray: #BFBFBF;
$gray--dark: #696969;

$warning--light: #fffae6;
$warning: #FAEFD3;
$warning--dark: #ff8b00;

$blue: #56CCF2;
$blue--dark: #42526E;
