@import "src/assets/css/mixins-variables.scss";

start-page {
  .mat-tab-label-container {
    background: #fafafa;
  }

  .mat-tab-link:hover {
    text-decoration: none;
  }

  .mat-tab-link {
    opacity: 1;

    @include lg {
      font-size: 18px;
    }

    @include mob {
      width: 50% !important;
      padding: 0 10px;
    }
  }

  .mat-tab-label-active {
    color: $color-main;
  }

  .mat-tab-links {
    padding: 33px 30px;

    @include mob {
      padding: 15px;
    }
  }

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $color-main;
    bottom: 35px;
    height: 3px;
    transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);

    @include mob {
      bottom: 20px;
    }
  }

  .mat-tab-label {
    padding: 0 12px;

    @include lg {
      padding: 0 24px;
    }
  }
}

registration {
  .mat-horizontal-content-container {
    margin-top: 0;
    padding: 0 !important;
  }

  .mat-step-header {
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 9px;

    @include lg {
      font-size: 16px;
      padding: 0 35px;
    }

    justify-content: center;

    .mat-step-icon {
      background: #d0978b;
    }

    .mat-step-icon-selected {
      background: $color-main;
    }
  }

  .mat-step-label {
    white-space: normal;

    @include lg {
      font-size: 16px;
    }
  }

  .mat-stepper-horizontal {
    @include lg {
      font-size: 16px;
    }
  }

  .mat-step-header:hover,
  .cdk-focused {
    background-color: #ebecef;
  }
}

external-registration {
  .mat-horizontal-content-container {
    margin-top: 0;
    padding: 0 !important;
  }

  .mat-horizontal-stepper-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    white-space: nowrap;
  }

  .mat-horizontal-stepper-header {
    padding: 0;
  }

  .mat-step-header {
    width: calc(100% / 3);
    height: 50px;

    &:not(:last-child) {
      margin-right: 20px;
    }

    .mat-step-icon {
      font-weight: 600;
      margin-right: 0;
      background: transparent;
      color: $text-color--main--dark;
      opacity: 0.5;
    }

    .mat-step-icon-selected {
      color: $text-color--main !important;
      opacity: 1;
    }

    .mat-step-icon-state-edit {
      color: $green;
      opacity: 1;
    }

    .mat-step-label {
      font-weight: 600;
      color: $text-color--main--dark;
      opacity: 0.5;

      &:after {
        position: absolute;
        left: 0;
        bottom: 5px;
        content: "";
        width: 100%;
        height: 5px;
        border-radius: 2px;
        background: $text-color--main--dark;
        opacity: 0.5;
      }
    }

    .mat-step-label-active {
      color: $green !important;
      opacity: 1;

      &:after {
        background: $green;
        opacity: 1;
      }
    }

    .mat-step-label-selected {
      color: $text-color--main !important;
      opacity: 1;

      &:after {
        background: $green;
        opacity: 0.5;

      }
    }
  }

  .mat-stepper-horizontal-line {
    display: none;
  }
}

.pure-modalbox {
  position: absolute !important;
  right: 0;
  // bottom: 0;
  height: 100vh;
  animation-name: animations-by-modal;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  width: 70vw;

  @include lg {
    width: 40vw;
  }

  .center-modal {
    position: relative !important;
    top: 0;
    right: 50%;
    animation-name: animations-by-modal;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
    width: 100%;

    @include lg {
      width: 300px;
    }
  }

  .mat-dialog-actions {
    a {
      &:first-child {
        @include lg {
          margin-right: 20px;
        }
      }

      &:last-child {
        margin-top: 10px;

        @include lg {
          margin-top: 0;
        }
      }

      @include lg {
        width: unset;
      }

      width: 100%;
      text-align: center;
    }

    button {
      margin-top: 10px;

      @include lg {
        width: unset;
        margin-top: 0;
      }

      width: 100%;
      text-align: center;
    }
  }
}

.mat-dialog-container {
  border-radius: 2px !important;
}

@keyframes animations-by-modal {
  from {
    // height: 0;
    width: 0;
  }

  to {
    height: 100vh;
  }
}

app-sign-kgd-drafts,
app-create-cashbox-sc,
create-trade-point,
app-connect-cashbox,
app-cashbox-registration,
app-cashbox-reregistration,
app-cashbox-deregister,
app-employee-registration,
app-employee-edit,
app-notification-settings {
  .mat-step-header .mat-step-icon-selected {
    background-color: $color-main;
    color: #ffffff;
    opacity: 1 !important;
  }

  .mat-step-header .mat-step-icon {
    background-color: $color-main;
    opacity: 0.3;
  }

  .mat-step-icon-content {
    color: #ffffff;
  }
}

app-cashboxes-list,
app-cashbox-info-layout,
support-layout,
app-sc-layout,
app-client-layout {
  a {
    width: 33.33%;
  }

  a.btn-primary {
    width: auto;
  }

  .mat-tab-link {
    margin-right: 10px;
    padding: 10px 30px;
    border-radius: 2px 2px 0px 0px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.02em;
    color: $text-color--main;
    background: linear-gradient(180deg, $white 0%, rgba(210, 213, 220, 0.42) 100%), $white;
    opacity: 1;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      text-decoration: none;
    }

    @include mob {
      padding: 10px;
    }

    @include lg {
      font-size: 18px;
    }
  }

  .mat-tab-label-active {
    color: $color-main;
    background: linear-gradient(180deg, rgba(161, 47, 24, 0.26) 0%, rgba(255, 255, 255, 0) 100%), $white;
    box-shadow: inset 0px 3px 0px $color-main;
    border-radius: 2px 2px 0px 0px;
  }

  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: transparent;
  }

  .mat-ink-bar {
    height: 4px;
  }

  .mat-tab-nav-bar {
    border-bottom: unset;
    padding: 0;
    background: transparent;
  }

  .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    background: $white;
  }
}

app-cashboxes-list {
  a {
    width: auto;
  }
}

app-cashbox-info-layout {
  .mat-tab-label-active {
    opacity: 1;
  }
}

support-users,
support-organizations,
support-users,
support-cashbox-details {

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: transparent;
  }
}

support-organizations {
  .mat-tab-body-wrapper {
    margin-top: 10px;
  }

  input {
    cursor: default;
  }
}

app-shifts {
  font-family: "SourceSansPro-Regular" !important;

  .btn-primary {
    background-color: $color-main !important;
    border-color: $color-main !important;
  }

  .btn-link {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 600;
    color: #a12f18 !important;

    &:hover {
      cursor: pointer;
      text-decoration: none;
      color: #a12f18 !important;
    }
  }

  td {
    vertical-align: middle !important;
  }
}

app-notification {
  .mat-expansion-panel-header-title {
    align-items: center;
  }

  .mat-expansion-panel-header-description {
    flex-grow: 0;
    align-items: center;
  }
}

.mat-tab-link {
  width: fit-content !important;
}

.dx-datagrid-header-panel {
  padding: 0 0;
}
