.icon-deposit:before {
  content: "\e900";
}
.icon-withdrawal:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e902";
}
.icon-close:before {
  content: "\e903";
}
.icon-rotate-cw:before {
  content: "\e904";
}
.icon-Vector-Stroke:before {
  content: "\e905";
}
.icon-bar-chart-2:before {
  content: "\e906";
}
.icon-plus:before {
  content: "\e907";
}
.icon-alert-circle:before {
  content: "\e908";
}
.icon-alert-triangle:before {
  content: "\e909";
}
.icon-at-sign:before {
  content: "\e90a";
}
.icon-bell:before {
  content: "\e90b";
}
.icon-briefcase:before {
  content: "\e90c";
}
.icon-chevron-down:before {
  content: "\e90d";
}
.icon-clock:before {
  content: "\e90e";
}
.icon-cloud:before {
  content: "\e90f";
}
.icon-credit-card:before {
  content: "\e910";
}
.icon-folder:before {
  content: "\e911";
}
.icon-layers:before {
  content: "\e912";
}
.icon-layout:before {
  content: "\e913";
}
.icon-log-out:before {
  content: "\e914";
}
.icon-mail:before {
  content: "\e915";
}
.icon-map-pin:before {
  content: "\e916";
}
.icon-pause-circle:before {
  content: "\e917";
}
.icon-readed-mail:before {
  content: "\e918";
}
.icon-settings:before {
  content: "\e919";
}
.icon-stop-circle:before {
  content: "\e91a";
}
.icon-thumbs-up:before {
  content: "\e91b";
}
.icon-tool:before {
  content: "\e91c";
}
.icon-user:before {
  content: "\e91d";
}
.icon-users:before {
  content: "\e91e";
}
.icon-x-octagon:before {
  content: "\e91f";
}
.icon-menu:before {
  content: "\e920";
}
.icon-error:before {
  content: "\e921";
}
.icon-cloud1:before {
  content: "\e922";
}
.icon-bh:before {
  content: "\e923";
}
.icon-check:before {
  content: "\e924";
}
.icon-Rectangle-24:before {
  content: "\e925";
}
.icon-Rectangle-24-1:before {
  content: "\e926";
}
.icon-globe:before {
  content: "\e927";
}
.icon-warning:before {
  content: "\e928";
}
.icon-profile:before {
  content: "\e929";
}
.icon-lock:before {
  content: "\e92a";
}
.icon-arrow-chevron-down:before {
  content: "\e92b";
}
.icon-download:before {
  content: "\e92d";
}
.icon-print:before {
  content: "\e92e";
}
.icon-circle:before {
  content: "\e92f";
}
.icon-check-circle:before {
  content: "\e930";
}
.icon-alert:before {
  content: "\e931";
}
.icon-done:before {
  content: "\e932";
}
.icon-emotion-sad:before {
  content: "\e933";
}
.icon-ban:before {
  content: "\e934";
}
.icon-money-stack:before {
  content: "\e935";
}
.icon-money-bill:before {
  content: "\e936";
}
.icon-download1:before {
  content: "\e937";
}
.icon-refresh:before {
  content: "\e938";
}
.icon-edit:before {
  content: "\e939";
}
.icon-trash:before {
  content: "\e93a";
}
.icon-more:before {
  content: "\e93b";
}
.icon-reload:before {
  content: "\e93c";
}
.icon-copy:before {
  content: "\e93d";
}
.icon-wallet:before {
  content: "\e92c";
}