@import "./adaptive.scss";
@import "src/assets/css/mixins-variables.scss";

@font-face {
  font-family: "SourceSansPro-Regular";
  src: url("/../../../assets/fonts/SourceSansPro-Regular.ttf");
}
@font-face {
  font-family: "SourceSansPro-SemiBold";
  src: url("/../../../assets/fonts/SourceSansPro-SemiBold.ttf");
}
@font-face {
  font-family: "SourceSansPro-Italic";
  src: url("/../../../assets/fonts/SourceSansPro-Italic.ttf");
}
@font-face {
  font-family: "SourceSansPro-Bold";
  src: url("/../../../assets/fonts/SourceSansPro-Bold.ttf");
}
@font-face {
  font-family: 'icomoon';
  src:  url('/../../../assets/fonts/icomoon.eot');
  src:  url('/../../../assets/fonts/icomoon.eot') format('embedded-opentype'),
  url('/../../../assets/fonts/icomoon.ttf') format('truetype'),
  url('/../../../assets/fonts/icomoon.woff') format('woff'),
  url('/../../../assets/fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  min-height: 100%;
}
.mobile {
  @include lg {
    display: none
  }
}
.desktop {
  @include mob {
    display: none;
  }
}
i {
  font-size: 1.5rem;
  color: $color-main;
  @include mob {
    font-size: 1rem;
  }
}

start-page,
external-registration,
external-cashbox-registration {
  .wrapper {
    background: $bg-color--secondary;
    height: 100vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: $color-main;
    &::-webkit-scrollbar {
      width: 16px;
      height: 16px;
      background-color: $bg-color--secondary;
    }
    &::-webkit-scrollbar-track {
      background-color: $bg-color--secondary;
    }

    &::-webkit-scrollbar-thumb {
      width: 6px;
      height: 6px;
      -webkit-border-radius: 14px;
      border-radius: 14px;
      border: 5px solid $bg-color--secondary;
      background-color: $color-main;
      &:hover {
        background-color: $color-main--light;
      }
    }
    &::-webkit-scrollbar-corner {
      background-color: $bg-color--main;
    }
    @include lg {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

@include mob {
  .out {
    position: relative;
    margin-right: 255px;
    .navigator {
      display: block !important;
      width: 255px !important;
      li:not(.navigator__mob-close) {
        justify-content: flex-start !important;
      }
      li.with-submenu {
        > div {
          justify-content: flex-start !important;
        }
      }
      li.with-submenu.with-submenu--open {
        ul {
          display: block !important;
        }
      }
      .hide {
        display: flex !important;
      }
    }
  }
}

@include lg {
  .navOpen {
    .layout__main {
      margin-left: $widthOfOpenNavigator !important;
    }
    .navigator {
      width: $widthOfOpenNavigator !important;
    }
  }
  .navClose {
    .layout__main {
      margin-left: $widthOfCloseNavigator;
    }
  }
}

.navOpen {
  .navigator {
    li:not(.navigator__mob-close), .li {
      justify-content: flex-start !important;
      padding-left: 25px !important;
    }
    li.navigator__reg {
      padding-left: 10px !important;
      justify-content: flex-start !important;
      > div {
        padding-left: 0 !important;
      }
    }
    li.with-submenu {
      > div {
        justify-content: flex-start !important;
      }
      @include lg {
        &:hover {
          > ul {
            margin-top: -10px;
          }
        }
      }
    }
    .logo {
      img {
        @include lg {
          width: 88px !important;
          margin-top: 10px !important;
          margin-bottom: 0 !important;
        }
      }
    }
    &__noLink {
      @include lg {
        border-bottom: none !important;
        width: fit-content !important;
        margin: 0 !important;
      }
    }
    .navigator__toggle {
      padding: 20px 10px !important;
      > div {
        transform: rotate(0deg) !important;
      }
    }
    .hide {
      display: flex !important;
      flex-direction: column;
    }
  }
}

body {
  margin: 0;
  font-family: "SourceSansPro-Regular", sans-serif !important;
  font-size: 13px;
  @include lg {
    font-size: 15px;
  }
  min-height: 100vh;
  position: relative;
  color: $text-color--secondary;
  overflow: hidden;
}

.xng-breadcrumb-root{
  display: flex;
  border-radius: 2px;
  background: $white;
  @include lg {
    margin-bottom: 20px !important;
    padding: 10px 30px;
  }
  @include mob {
    margin-bottom: 10px !important;
    padding: 10px 20px;
  }
  .xng-breadcrumb-link {
    text-decoration: underline;
    color: $text-color--main;
    &:focus {
      outline: none;
    }
  }
  .xng-breadcrumb-separator {
    color: $text-color--main;
  }
  .xng-breadcrumb-trail {
    margin-bottom: 0;
    color: $text-color--main--dark;
  }
}

.layout__content__inner {
  > * {
    > *:not(:last-child) { // Любой дочерний блок
      @include mob {
        margin-bottom: 10px;
      }
      @include lg {
        margin-bottom: 20px;
      }
    }
  }
}

.block {
  padding: 15px;
  border-radius: 2px;
  background: $white;
  @include lg {
    padding: 20px 30px;
  }
}
nav + .block {
  border-radius: 0 2px 2px;
}

.cdk-global-scrollblock {
  // Переназначение в целях вывода скролла у mat-dialog при необходимости
  overflow-y: auto;
}

.mat-dialog-container {
  height: initial !important;
}
.mat-dialog-content {
  @include lg {
    max-height: calc(100% - 95px) !important;
  }
}
.mat-form-field-subscript-wrapper {
  position: relative !important;
}

.mat-step {
  @include lg {
    font-size: 16px;
  }
}
.mat-horizontal-content-container {
  padding: 0 30px 20px !important;
  @include mob {
    padding: 0 15px 15px !important;
  }
}
.append-arrow {
  position: relative !important;
  padding-right: 45px !important;
}

.append-arrow:before,
.append-arrow:after {
  content: " ";
  position: absolute;
  top: 50%;
  margin-top: -1px;
  width: 10px;
  height: 2px;
  right: 20px;
  transform-origin: right;
}

.append-arrow:before {
  transform: rotate(-50deg);
}

.append-arrow:after {
  transform: rotate(50deg);
}

.form-control.ng-invalid.ng-touched {
  border: 2px solid #cc3737;
  box-shadow: 0 2px 10px inset rgba(150, 0, 0, 0.2);
}

.form-control {
  height: 40px;
  border: 1px solid rgba(59, 62, 72, 0.15);
  box-sizing: border-box;
  border-radius: 2px;
  background: #f6f7f9;
  color: black;
  &:focus {
    box-shadow: unset;
    border-color: unset;
    border: 2px solid rgba(59, 62, 72, 0.7);
  }
  &:hover {
    border: 2px solid rgba(59, 62, 72, 0.5);
  }
  &:disabled {
    cursor: not-allowed;
  }
}
.btn-grp {
  display: flex;
  flex-direction: row;
  text-align: center;
  @include mob {
    flex-direction: column-reverse;
  }
  button {
    @include mob {
      width: 100%;
    }
  }
  button:not(:last-child) {
    @include lg {
      margin-right: 20px;
    }
  }
  button:not(:first-child) { // потому что column-reverse
    @include mob {
      margin-bottom: 10px;
    }
  }
}
.btn-list {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  @include mob {
    flex-direction: column-reverse;
  }
  > button:last-child {
    margin-left: 20px;
    @include mob {
      margin-bottom: 10px;
      margin-left: 0;
    }
  }
}
.btn-silver {
  display: flex; // для нижних двух пунктов
  justify-content: center; // на широкую кнопку, чтобы контент кнопки был отцентрирован
  align-items: center; // чтобы с иконками кнопка была отцентрирована вертикально
  padding: 10px 16px;
  border: 0;
  border-radius: 2px;
  font-weight: 600;
  line-height: 1rem;
  color: $text-color--main;
  background: $bg-color--main--light;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    background-color: $bg-color--main;
  }
  &:disabled {
    opacity: 0.5;
  }
  i {
    margin-right: 10px;
    font-size: 1em;
    color: $text-color--main;
  }
}
.btn-primary {
  display: flex; // для нижних двух пунктов
  justify-content: center; // на широкую кнопку, чтобы контент кнопки был отцентрирован
  align-items: center; // чтобы с иконками кнопка была отцентрирована вертикально
  padding: 10px;
  background: $color-third;
  border-radius: 2px;
  line-height: 1rem;
  border: 0;
  outline: none;
  cursor: pointer;
  color: $white !important;
  &:hover {
    text-decoration: none;
    background: $color-third--dark;
    color: $white;
  }
  &:disabled {
    opacity: 0.5;
  }
  i {
    margin-right: 10px;
    font-size: 1em;
    color: $white;
  }
}
.btn-square {
  margin-right: 20px;
  padding: 10px;
  border: none;
  border-radius: 2px;
  @include mob {
    width: 36px;
    height: 36px;
  }
}
.btn-square.bg-silver {
  margin-right: 0;
}
.bg-red {
  background: $color-secondary;
}
.bg-primary {
  background: $color-third;
  &:hover {
    background: $color-third--dark;
  }
  i {
    color: $white;
  }
  &:hover,
  &:focus {
    cursor: pointer;
  }
}
.bg-silver {
  background: #f3f3f3;
  &:hover {
    background: #dedede;
  }
  &:hover,
  &:focus {
    cursor: pointer;
  }
}

.actions {
  display: flex;
  @include mob {
    flex-direction: column;
  }
  &--col {
    display: flex;
    flex-direction: column;
  }
}

a.text-link {
  width: auto;
  margin: auto auto 1px;
  text-align: center;
  text-decoration: underline;
  color: $color-main;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: $color-main;
    cursor: pointer;
  }
}
a.text-link.disabled {
  text-decoration: none;
  color: $text-color--secondary;
  cursor: not-allowed;
}
.required-field:after {
  content:" *";
  color: #ec1239;
}
select,
button {
  &:focus {
    outline: unset;
  }
}
select::-ms-expand {
  display: none;
}
.select-wrapper {
  position: relative;
}

.select-wrapper:before {
  // content: "\e907";
  // font-family: "icomoon";
  color: #455473;
  display: inline-block;
  position: absolute;
  right: 16px;
  top: 20px;
  pointer-events: none;
  font-size: 5px;
}
// Статусы для касс
.status {
  display: flex;
  width: fit-content;
  margin: auto;
  padding: 2px 8px;
  border-radius: 3px;
  font-weight: 600;
}
.status.active {
  color: $white;
  background: $green;
}
.status.locked {
  color: $white;
  background: $red;
}
.status.waitReregistration {
  background: $gray;
}
.status.waitDeregistration {
  background: $gray;
}
.status.deregistered {
  color: $white;
  background: $blue--dark;
}
.status.waitDisconnection {
  background: $gray;
}
.status.disconnected {
  background: $blue;
}
.status.waitCancelDisconnect {
  background: $gray;
}
// Статусы для услуг кассы
.status-label {
  display: flex;
  width: fit-content;
  margin: auto;
  padding: 2px 8px;
  border-radius: 3px;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
}
.status-label.none {
  color: $gray--dark;
  background-color: $gray--light;
}

.status-label.active {
  color: $green;
  background-color: $green--lighten;
}

.status-label.expired {
  color: $red--dark;
  background-color: $red--lighten;
}

label {
  color: $text-color--main;
}
.value {
  width: 100%;
  padding: 10px 22px;
  font-family: "SourceSansPro-SemiBold";
  background: $bg-color--main--lighten;
  color: $text-color--main;
  @include mob {
    padding: 10px;
  }
}
.eds-block {
  color: $text-color--main--light;
  &__title {
    position: relative;
    display: block;
    margin: 15px 0;
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
    color: $color-main;
    > span:first-child,
    > span:last-child {
      position: absolute;
      top: 50%;
      width: 20%;
      border-bottom: 2px solid $color-main;
      @include sm {
        display: none;
      }
    }
    > span:first-child {
      left: 0;
    }
    > span:last-child {
      right: 0;
    }
  }
  &__header {
    align-self: baseline;
    margin-top: 0;
  }
  &__action {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    .btn-primary {
      width: 48%;
    }
  }
  small {
    margin: 0 10px;
  }
  i {
    color: $color-secondary;
    font-size: 1rem;
    margin-right: 10px;
  }
}

.eds-btns {
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
  &__action {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    .btn_blue,
    .btn_red {
      width: 45%;
    }
  }
  &__header {
    align-self: baseline;
    margin-top: 0;
  }
  .btn-primary {
    width: 48%;
  }
  small {
    margin: 0 10px;
  }
  i {
    font-size: 1rem;
    margin-right: 10px;
    color: $white;
  }
}
.eds-info {
  margin: 15px 0;
  padding: 20px;
  font-size: 0.8em;
  background: #f6f7f9;
  .couple {
    margin-bottom: 0.5em;
    span:first-child {
      color: #a2aab9;
    }
  }
  h5,
  span,
  p {
    margin: 0;
  }
  h5 {
    font-family: "SourceSansPro-SemiBold";
    font-size: 1.1rem;
    color: $text-color--main;
  }
  h5,
  div:first-child {
    margin-bottom: 5px;
  }
  &-block {
    > div:first-child {
      margin-right: 20px;
    }
  }
  > div {
    span,
    p {
      &:last-child {
        font-family: "SourceSansPro-SemiBold";
        color: $text-color--main;
      }
    }
  }
}


.message {
  &--warning {
    margin: 15px 0;
    padding: 10px;
    font-size: 13px;
    color: $text-color--main;
    background: $warning;
    p {
      margin: 0;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
}

.block--warning {
  margin: 1em 0;
  padding: 18px;
  border-radius: 3px;
  background: $warning--light;
  font-weight: 600;
  color: $text-color--main;
  i {
    margin-right: 1em;
    font-size: 1em;
    color: $warning--dark;
  }
  .block--message{
    font-weight: normal;
  }
}

.info-block {
  margin-bottom: 20px;
  padding: 20px;
  background: $bg-color--main--light;
  p {
    margin: 0;
  }
  span {
    display: block;
    margin-bottom: 15px;
    font-weight: 600;
    color: $text-color--main;
  }
}

.with-link {
  &:focus {
    outline: none;
  }
  &:hover,
  &:focus {
    cursor: pointer;
  }
}

.header-popovers-wrapper {
  .dx-popup-content {
    padding: 15px;
  }
  .dx-template-wrapper {
    height: auto !important;
  }
  p {
    margin: 0 0 10px;
    font: {
      size: 14px;
      weight: 600;
    }
    color: $text-color--main;
    &:hover,
    &:focus {
      outline: none;
      cursor: pointer;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.notifications-popover-wrapper {
  .dx-popup-content {
    padding: 0;
  }

  .dx-template-wrapper {
    height: auto !important;
  }

}
// end app-cabinet-header
.ticket-view-popovers-wrapper {
  .dx-popup-content {
    padding: 0;
  }
}
.actions-popover-wrapper {
  .dx-popup-content {
    padding: 0;
  }
}
.actions-list {
  display: flex;
  margin-bottom: 5px;
  @include mob {
    flex-direction: column;
  }
  &__left {
    display: flex;
    width: 100%;
    align-items: flex-end;

    > * {
      width: max-content;
      height: 100%;
      @include lg {
        margin-right: 10px;
      }
      @include mob {
        width: 100%;
        margin-right: 3px;
      }
    }
  }
  &__right {
    display: flex;
    align-items: flex-end;
    @include mob {
      flex-direction: column;
    }
    > * {
      width: max-content;
      height: 100%;
      @include lg {
        margin-left: 10px;
      }
      @include mob {
        width: 100%;
        margin-left: 3px;
      }
    }
  }
}
